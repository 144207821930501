import ParenthesisSvg from '../../images/icon/parenthesis.svg'
import { media } from '../../theme/mixins/media'
import styled from 'styled-components/macro'
import rem from 'polished/lib/helpers/rem'

export const Parenthesis = styled(ParenthesisSvg)`
  ${media.onlyMobile`
    width: ${rem(60)};
  `}
`
