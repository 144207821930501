import { rem } from 'polished'
import styled from 'styled-components/macro'
import { Text } from '../../../components/Text'

export const Title = styled(Text).attrs({
  as: 'h1',
  size: 'h1',
  family: 'merienda',
})`
  text-align: center;
  max-width: ${rem(865)};

  margin-left: auto;
  margin-right: auto;
`
