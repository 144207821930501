import React from 'react'
import { Container } from '../../components/grid/Container'
import { Row } from '../../components/grid/Row'
import { Separator } from '../../components/Separator'
import { Col } from '../../components/grid/Col'
import { List } from '../../components/List'
import styled from 'styled-components/macro'
import { rem } from 'polished'
import { sizes } from '../../theme/sizes'
import { color } from '../../theme/color'
import { Text } from '../../components/Text'
import { Block } from '../../components/Block'
import { Button } from '../../components/Button/Button'
import { media } from '../../theme/mixins/media'
import { grid } from '../../theme'
import { Link } from 'gatsby'
import { Textos } from '../../pages'
import { PortableText } from '../../components/PortableText'

interface Props {
  dolencias: { nombre: string }[]
  textos: Textos
}

export const DolenciasSection: React.FC<Props> = ({ dolencias, textos }) => (
  <Container>
    <Row>
      <BodyCol>
        <Text size="h2" weight="light" uppercase center>
          {textos.dolencias_Titulo}
        </Text>

        <Separator />

        <Text as="div" size="large" weight="light" center marginBottom="size7">
          <PortableText blocks={textos._rawDolenciasSubtitulo} />
        </Text>

        <Block marginBottom="size7">
          <DolenciasList>
            {dolencias.map((dolencia, index) => (
              <List.Item key={index}>
                <Text weight="bold">{dolencia.nombre}</Text>
              </List.Item>
            ))}
          </DolenciasList>
        </Block>

        <Block flex justify="center">
          <Button as={Link} variant="secondary" to="/que-tratamos">
            Más información
          </Button>
        </Block>
      </BodyCol>
    </Row>
  </Container>
)

const BodyCol = styled(Col).attrs({ tablet: { start: 2, size: 10 } })`
  padding: ${rem(sizes.size7)} ${rem(grid.default.gap)};
  background-color: ${color.gray4};

  ${media.tablet`
    padding: ${rem(sizes.size8)};
    margin-top: ${rem(-130)};
  `}

  ${media.desktop`
    padding: ${rem(sizes.size9)};
  `}
`

const DolenciasList = styled(List)`
  ${media.desktop`
    column-count: 2;
  `}
`
