import NormalLogoSvg from '../../../images/logo.svg'
import ChristmasLogoSvg from '../../../images/christmas/logo.svg'
import rem from 'polished/lib/helpers/rem'
import styled from 'styled-components/macro'
import { media } from '../../../theme/mixins/media'
import { config } from '../../../config'

const NormalLogo = styled(NormalLogoSvg)`
  width: ${rem(305 / 2.5)};
  height: ${rem(154 / 2.5)};

  /* COVID*/
  /* ${media.tablet`
    width: ${rem(305)};
    height: ${rem(154)};
  `} */

  ${media.tablet`
    width: ${rem(305 / 1.5)};
    height: ${rem(154 / 1.5)};
  `}
`

const ChristmasLogo = styled(ChristmasLogoSvg)`
  width: ${rem(305 / 2.5)};
  height: ${rem(154 / 2.5)};

  /* COVID*/
  /* ${media.tablet`
    width: ${rem(305)};
    height: ${rem(154)};
  `} */

  ${media.tablet`
    width: ${rem(305 / 1.5)};
    height: ${rem(154 / 1.5)};
  `}
`
console.log(config.enableChristmasTheme)
export const Logo = config.enableChristmasTheme ? ChristmasLogo : NormalLogo
