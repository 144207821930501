import React, { Suspense } from 'react'
import styled from 'styled-components/macro'
import { Text } from '../../../components/Text'
import { Separator } from '../../../components/Separator'
import { sizes } from '../../../theme/sizes'
import rem from 'polished/lib/helpers/rem'
import { Block } from '../../../components/Block'
import { Link } from '../../../components/Link'
import { color } from '../../../theme/color'
import FlorIzquierdaImg from '../../../images/flor-izquierda.jpg'
import FlorDerechaImg from '../../../images/flor-derecha.jpg'

import loadable from '@loadable/component'
import { media } from '../../../theme/mixins/media'
import { MobileContent } from '../../../components/grid/MobileContent'
import { Parenthesis } from '../Parenthesis'
import { grid } from '../../../theme'
import { Textos } from '../../../pages'

const OpinionesSlider = loadable(() => import('./OpinionesSlider'))

interface Props {
  textos: Textos
}

export const OpinionesSection: React.FC<Props> = ({ textos }) => (
  <Wrapper>
    <Block
      flex
      justify="center"
      marginBottom="size5"
      tablet={{ marginBottom: 'size7' }}
    >
      <Parenthesis />
    </Block>

    <MobileContent>
      <Text size="h2" weight="light" uppercase center>
        Qué opinan nuestros clientes
      </Text>
    </MobileContent>

    <Separator />

    <OpinionesSlider textos={textos} />

    <LinkWrapper>
      <ReseñasLink
        rightArrow
        component="a"
        href="http://bit.ly/valoraciones-centro-feeling"
        target="_blank"
      >
        Ver reseñas en google
      </ReseñasLink>
    </LinkWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  margin-top: ${rem(sizes.size6)};
  margin-bottom: ${rem(sizes.size7)};

  ${media.tablet`
    margin-top: ${rem(sizes.size9)};
    margin-bottom: ${rem(sizes.size9)};
  `}

  ${media.tablet`
    &::before,
    &::after {
      content: '';
      position: absolute;
      background-size: cover;
      z-index: -10;
    }

    &::before {
      left: -60px;
      top: -30px;
      width: ${rem(510)};
      height: ${rem(626)};
      background-image: url(${FlorIzquierdaImg});
    }

    &::after {
      right: -30px;
      top: -90px;
      width: ${rem(316)};
      height: ${rem(654)};
      background-image: url(${FlorDerechaImg});
    }
  `}

  .carousel-dots {
    margin-top: 0;

    li button:before {
      color: ${color.gray3};
      opacity: 1;
      font-size: 25px;
      transition: all 0.3s;
    }

    li.carousel-dots-active button:before {
      color: ${color.turquoise};
      opacity: 1;
      font-size: 40px;
    }
  }
`

const LinkWrapper = styled.div`
  display: flex;

  max-width: ${rem(860)};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${rem(grid.default.gap)};
  padding-right: ${rem(grid.default.gap)};
  margin-top: ${rem(sizes.size5)};

  justify-content: center;

  ${media.tablet`
    margin-top: 0;
    justify-content: flex-end;
  `}
`

const ReseñasLink = styled(Link)`
  ${media.tablet`
    position: relative;
    top: ${rem(-sizes.size5)};
  `}
`
