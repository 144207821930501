import React from 'react'

import { MainHeroMobile } from './MainHeroMobile'
import { MainHeroTablet } from './MainHeroTablet'

export const MainHero = () => (
  <>
    <MainHeroMobile />
    <MainHeroTablet />
  </>
)
