import React from 'react'
import { Text } from '../../components/Text'
import { Block } from '../../components/Block'
import styled from 'styled-components/macro'
import { sizes } from '../../theme/sizes'
import rem from 'polished/lib/helpers/rem'
import { media } from '../../theme/mixins/media'
import CitaBgUrl from '../../images/cita-bg.jpg'
import { Parenthesis } from './Parenthesis'
import { Textos } from '../../pages'

interface Props {
  textos: Textos
}

export const CitaSection: React.FC<Props> = ({ textos }) => (
  <Wrapper>
    <Block
      flex
      justify="center"
      marginBottom="size5"
      tablet={{ marginBottom: 'size7' }}
    >
      <Parenthesis />
    </Block>

    <TextWrapper>
      <Text size="large" tablet={{ size: 'h3' }} weight="light" center>
        {textos.frase}
      </Text>
    </TextWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  margin-top: ${rem(sizes.size7)};
  padding-bottom: ${rem(240)}; /** altura del fondo y un poco más */

  text-align: center;

  background-image: url(${CitaBgUrl});
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 800px auto;

  ${media.tablet`
    margin-top: ${rem(sizes.size9)};
    margin-bottom: 0;

  `}

  ${media.desktop`
    padding-bottom: 300px; /** altura del fondo y un poco más */
    background-size: 1000px auto;
  `}

  ${media.desktopLarge`
    padding-bottom: 450px; /** altura del fondo y un poco más */
    background-size: auto;
  `}
`

const TextWrapper = styled.div`
  max-width: ${rem(860)}; /** Por diseño */
  padding-left: ${rem(sizes.size6)};
  padding-right: ${rem(sizes.size6)};
  margin-left: auto;
  margin-right: auto;
`
