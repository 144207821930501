import React from 'react'
import { Text } from '../../components/Text'
import { Separator } from '../../components/Separator'
import { Container } from '../../components/grid/Container'
import { Block } from '../../components/Block'
import { Button } from '../../components/Button/Button'
import { MobileContent } from '../../components/grid/MobileContent'
import { Image } from '../../components/Image'
import { Row } from '../../components/grid/Row'
import { Col } from '../../components/grid/Col'
import { LinkedInIcon } from '../LinkedInIcon'
import { Link } from 'gatsby'
import { rem } from 'polished'
import { Textos } from '../../pages'
import { imageUrlFor } from '../../lib/image-url'
import { buildImageObj } from '../../lib/helpers'
import { PortableText } from '../../components/PortableText'
import { HtmlContent } from '../../components/HtmlContent/HtmlContent'

interface Props {
  textos: Textos
}

export const ConocemeSection: React.FC<Props> = ({ textos }) => (
  <Container
    marginTop="size7"
    marginBottom="size7"
    tablet={{ marginTop: 'size9', marginBottom: 'size9' }}
  >
    <Block matchMedia="upToDesktop">
      <MobileContent>
        <Block flex align="center" justify="center">
          <Text size="h2" weight="light" uppercase center marginRight="size5">
            Conóceme
          </Text>
          <LinkedInIcon />
        </Block>
        <Separator />
        <Image
          rounded
          center
          ratio="1by1"
          src={imageUrlFor(buildImageObj(textos.conocemeFoto))
            .width(500)
            .height(Math.floor(500))
            .auto('format')
            .url()}
          marginBottom="size5"
          style={{ maxWidth: rem(250) }}
        />
        <Text as="div" marginBottom="size5" weight="light" center>
          <HtmlContent>
            <PortableText blocks={textos._rawConocemeContenido} />
          </HtmlContent>
        </Text>
        <Block textAlign="center">
          <Button as={Link} variant="secondary" to="/codes-cestau-apesteguia">
            Saber más sobre mí
          </Button>
        </Block>
      </MobileContent>
    </Block>

    <Block matchMedia="desktop">
      <>
        <Row align="center">
          <Col desktop={{ start: 2, size: 5 }}>
            <Image
              rounded
              ratio="1by1"
              src={imageUrlFor(buildImageObj(textos.conocemeFoto))
                .width(500)
                .height(Math.floor(500))
                .auto('format')
                .url()}
            />
          </Col>
          <Col desktop={5}>
            <Block flex align="center" justify="center">
              <Text
                size="h2"
                weight="light"
                uppercase
                center
                marginRight="size5"
              >
                Conóceme
              </Text>
              <LinkedInIcon />
            </Block>
            <Separator />
            <Text as="div" marginBottom="size5" center weight="light">
              <HtmlContent>
                <PortableText blocks={textos._rawConocemeContenido} />
              </HtmlContent>
            </Text>
            <Block textAlign="center" marginTop="size7">
              <Button
                variant="secondary"
                as={Link}
                to="/codes-cestau-apesteguia"
              >
                Saber más sobre mí
              </Button>
            </Block>
          </Col>
        </Row>
      </>
    </Block>
  </Container>
)
