import React from 'react'
import { GlobalStyles } from '../theme/GlobalStyles'
import { MainHero } from '../partials/index/MainHero/MainHero'
import { DolenciasSection } from '../partials/index/DolenciasSection'
import {
  TecnicasSection,
  CategoriaTecnicas,
} from '../partials/index/TecnicasSection'
import { OpinionesSection } from '../partials/index/OpinionesSection'
import { TarifaSection } from '../partials/index/TarifaSection'
import { ConocemeSection } from '../partials/index/ConocemeSection'
import { CitaSection } from '../partials/index/CitaSection'
import { MainFooter } from '../partials/MainFooter'
import { Mapa } from '../partials/index/Mapa'
import { graphql } from 'gatsby'
import { Layout } from '../containers/Layout'
import { GraphqlErrorList } from '../components/GraphqlErrorList'
import { mapEdgesToNodes } from '../lib/helpers'
import { InstagramSection } from '../partials/InstagramSection'
import { Helmet } from 'react-helmet'
import { Seo } from '../partials/Seo'

interface Opinion {
  nombre: string
  texto: string
  _key: string
}

export interface Textos {
  frase: string
  opinionesListado: Opinion[]
  conocemeFoto: any
  _rawConocemeContenido: any
  _rawQueTratamosSubtitulo: any
  queTratamos_titulo: string
  queTratamos_fondo: any
  dolencias_Titulo: string
  _rawDolenciasSubtitulo: any
  tecnicas_titulo: string
  _rawTecnicasSubtitulo: any
}

export const query = graphql`
  query HomePage {
    sanityTextos {
      frase
      opinionesListado {
        nombre
        texto
        _key
      }
      conocemeFoto {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      _rawConocemeContenido
      dolencias_Titulo
      _rawDolenciasSubtitulo
      tecnicas_titulo
      _rawTecnicasSubtitulo
    }

    allSanityCategoriaTecnicas {
      edges {
        node {
          id
          fichaDescripcion
          slug {
            current
          }
          fichaImagen {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }

    allSanityDolencia {
      edges {
        node {
          nombre
        }
      }
    }
    #allInstaNode {
    #  edges {
    #    node {
    #      id
    #      mediaType
    #      thumbnails {
    #        config_height
    #        config_width
    #        src
    #      }
    #    }
    #  }
    #}
  }
`

const Page = (props: any) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphqlErrorList errors={errors} />
      </Layout>
    )
  }

  const categoriaTecnicas = mapEdgesToNodes(
    data.allSanityCategoriaTecnicas,
  ) as CategoriaTecnicas[]

  const dolencias = mapEdgesToNodes(data.allSanityDolencia) as {
    nombre: string
  }[]

  const textos = data.sanityTextos as Textos

  // const instagramNodes = mapEdgesToNodes(data.allInstaNode)

  return (
    <>
      <Seo title="Centro Feeling - Centro de osteopatía y fisioterapia en Pamplona" />
      <GlobalStyles />
      <MainHero />
      <DolenciasSection textos={textos} dolencias={dolencias} />
      <TecnicasSection textos={textos} categorias={categoriaTecnicas} />
      <OpinionesSection textos={textos} />
      <TarifaSection />
      <ConocemeSection textos={textos} />
      {/* <InstagramSection nodes={instagramNodes} /> */}
      <Mapa />
      <CitaSection textos={textos} />
      <MainFooter />
    </>
  )
}

export default Page
