import React, { useState } from 'react'
import { HeaderBar } from './HeaderBar'
import { Block } from '../../../components/Block'
import { MainMenuDesktop } from './MainMenuDesktop'
import { Title } from './Title'
import { Stack } from '../../../components/Stack'
import { Icon } from '../../../components/Icon/Icon'
import { Button } from '../../../components/Button/Button'
import { CitaPreviaModal } from '../../CitaPreviaModal'
import styled, { css } from 'styled-components/macro'
import { media } from '../../../theme/mixins/media'
import { Text } from '../../../components/Text'
import ClockSvg from '../../../images/icon/clock.svg'
import { rem } from 'polished'
import RightArrowSvg from '../../../images/icon/right-arrow.svg'
import { sizes } from '../../../theme'
import { InstagramIcon } from '../../InstagramIcon'
import { FacebookIcon } from '../../FacebookIcon'
import { Link } from '../../../components/Link'
import { config } from '../../../config'

import homeBgUrl from '../../../images/home-bg.jpg'
import homeBgUrlChristmasJpg from '../../../images/christmas/home-christmas.jpg'
import homeBgUrlChristmasWebp from '../../../images/christmas/home-christmas.webp'
import HorarioMamaPng from '../../../images/maternidad/apertura-agenda.png'
import { Logo } from './Logo'

export const MainHeroTablet = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <Wrapper>
      <HeaderBar />

      <Block marginTop="size6" marginBottom="size7" flex justify="center">
        <Logo />
      </Block>

      <Block marginTop="size7" marginBottom="size7" flex justify="center">
        <MainMenuDesktop />
      </Block>

      <Block marginBottom="size7">
        <Title>
          Centro de Fisioterapia <br /> & Osteopatía
        </Title>

        <SocialWrapper>
          <Block marginBottom="size6">
            <InstagramIcon big />
          </Block>
          <FacebookIcon big />
        </SocialWrapper>
      </Block>

      <Block flex justify="center" marginBottom="size7">
        <Stack horizontal="size3" align="center">
          <Icon size="medium" component={<ClockSvg />} />
          <Block matchMedia="tablet">
            {/* <img src={HorarioMamaPng} style={{ maxWidth: '400px' }} /> */}
            <Text size="base" weight="light">
              Lunes: 9h a 11h y 12:30h a 18h
              <br />
              Martes: 10h a 17h
              <br />
              Miércoles: 9h a 11h y 13:30h a 18h
              <br />
              Jueves y viernes: 9h a 16h
            </Text>
          </Block>
        </Stack>
      </Block>

      <Covid>
        <Text marginBottom="size3">Nuestras medidas contra la COVID19</Text>
        <Link to="/medidas-seguridad-covid-19">MÁS INFORMACIÓN</Link>
      </Covid>

      <Block flex justify="center">
        <Button primary onClick={() => setOpen(true)}>
          Cita previa
        </Button>
      </Block>

      <CitaPreviaModal isOpen={isOpen} onRequestClose={() => setOpen(false)} />

      <Block flex justify="center" marginTop="size8">
        <RightArrow />
      </Block>
    </Wrapper>
  )
}

const Covid = styled.div`
  padding: ${rem(sizes.size4)};
  margin-bottom: ${rem(sizes.size7)};

  margin-left: auto;
  margin-right: auto;

  background-color: #f2f2f2a3;
  text-align: center;
  max-width: 45rem;
`

const Wrapper = styled.div`
  height: calc(100vh + ${rem(130)});
  max-height: ${rem(600)};
  min-height: ${rem(1000)}; /* Para que no se solape el contenido */
  padding-top: 0;

  background-image: url(${homeBgUrl});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;

  ${config.enableChristmasTheme &&
  css`
    background-size: auto 100%;
    background-image: url(${homeBgUrlChristmasJpg});
    .webp & {
      background-image: url(${homeBgUrlChristmasWebp});
    }
  `}

  ${media.onlyMobile`
    display: none;
  `}
`

const RightArrow = styled(RightArrowSvg)`
  transform: rotate(90deg);
`

const SocialWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  right: ${rem(sizes.size6)};
`
